import React from 'react';
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import "./packed_store_page.5543905a.css"
import logo from './media/logo.png';
import product from './media/product.png';
import Collapsible from 'react-collapsible';
import './collapsible.scss';


import './Home.css';

class Home extends React.Component { 

  constructor() {
    super();
    this.state = { 
     mobile: false
    };
    
  }

  componentDidMount()
  {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});
    }  
    
  }

  

  
  render()
  {  

    console.log(document.getElementsByClassName("product"))
    


    return (
      <div className="pageContainer" style={{overflow: this.state.mobile ? 'visible' : 'hidden', animationDelay: '.5s'}}>  
        <div className='container' >

          
        
          <div className='left-half' style={{width: '100%', height: 'auto'}}>
          
            <center>
              <br></br>
              <br></br>
              <link rel="preload" href="https://themellowyellowmedia.b-cdn.net/light_assets/logo_lowRes.png"></link>
              <img src={logo} className='logo'></img>
              <br></br>
              <br></br>
             
              
                  
    <br></br>
             
              
           
              
              

              <div className='containBorderAsset'>
              <iframe src="https://badpathmedia.sellfy.store/embed/product/q9e1ai/" width="100%" height="430" allowtransparency="true" style={{border: 'none', overflow: 'none !important'}}></iframe>
              <iframe
                    className="video"
                    src={`https://www.youtube.com/embed/ZvtRc_-QAjQ`}
                    frameBorder="0"
                  />
              <iframe src="https://badpathmedia.sellfy.store/embed/product/rr0f8g/" width="100%" height="430" allowtransparency="true" style={{border: 'none', overflow: 'none !important'}}></iframe>
             

                
                
                <br></br>
                <br></br>
                <br></br>

                


                
              </div>

             {/*} <div className='containBorderAsset'>
                <img src="https://themellowyellowmedia.b-cdn.net/light_assets/mushrooms_1.png" className='borderAsset' loading="lazy"></img>
              </div>

              <div
                  className="video"
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 ,
                    paddingTop: 25,
                    height: 0,
                    
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: '50%',
                      left: '50%',
                      width: "80%",
                      height: "80%",
                      border: '.5em solid yellow',
                      alignSelf: 'center',
                      backgroundColor: 'yellow',
                    
                      transform: 'translate(-50%, -0%)'
                    }}
                    src={`https://www.youtube.com/embed/ZNXhjrj7byY`}
                    frameBorder="0"
                    loading="lazy"
                  />
                  </div>
                  <br></br>*/}
        
              {/*</center><div className='containBorderAsset2'>
                <img src="https://themellowyellowmedia.b-cdn.net/light_assets/spaceship_1.png" className='borderAsset2' loading="lazy"></img>
              </div>

              <div
                  className="video"
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9,
                    paddingTop: 25,
                    height: 0,
                    marginBottom: '40%'
                    
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: '50%',
                      left: '50%',
                      width: "80%",
                      height: "80%",
                      border: '.5em solid yellow',
                      backgroundColor: 'yellow',
                      alignSelf: 'center',
                    
                      transform: 'translate(-50%, 20%)'
                    }}
                    src={`https://www.youtube.com/embed/ZNXhjrj7byY`}
                    frameBorder="0"
                    loading="lazy"
                  />
                  </div>

                  <br></br>*/}
                
            
            
                </center>
            
            </div>
        </div>
    </div>

    )
    
    
          
      
  }
}
export default Home;
